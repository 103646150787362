import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import logo from "../../assets/ice-logo.webp";
import "./nav.scss"



const Nav = ({ }) => {

    //const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    //const listenScrollEvent = (e) => setIsScrolled(window.scrollY > 0);

    useEffect(() => {

        const listenScrollEvent = (e) => setIsScrolled(window.scrollY > 0);

        window.addEventListener("scroll", listenScrollEvent);

        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };

    }, [])


    return (
        <div className="sticky-nav">

            {/* <div className="top-nav">
                <div className="navbar-top">
                    <div>
                        <ul className="social-links">
                            <li>
                                <a href="" className="social-page"><BsFillTelephoneFill style={{ marginRight: "10px" }} /></a>
                            </li>
                            <li>
                                <a href="" className="social-page" style={{ paddingLeft: "10px" }} aria-label="Facebook"><FaFacebookF /></a>
                            </li>
                            <li>
                                <a href="" className="social-page" style={{ paddingLeft: "10px" }} aria-label="XTwitter"><FaXTwitter /></a>
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                        <ul className="btns">
                            <li className="">
                                <a href="" className=""></a>
                            </li>
                            <li className="">
                                <a href="" className=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}

            <nav className="uk-navbar-container" uk-navbar="true">

                <div className="uk-navbar-left navbar-left">
                    <Link to="/" aria-label="ICE Logo">
                        <img src={logo} className="transition-logo" alt="ICE Logo" width="150" />
                    </Link>
                </div>

                <div className="uk-navbar-right navbar-right uk-visible@l">
                    <div>
                        <ul className="uk-navbar-nav menu">
                            <li>
                                <a href="/about-us" className="menu-link">About Us</a>
                                {/* <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 18">
                                    <div className="submenu-container__display-flex">
                                        <a href=""></a>
                                    </div>
                                </div> */}
                            </li>
                            <li>
                                <a href="/our-brands" className="menu-link">Our Brands</a>
                            </li>
                            <li>
                                <a href="/contact-us" className="menu-link">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>




                <div className="uk-navbar-right uk-hidden@l">
                    <a
                        href=""
                        className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #mobile-width-menu"
                        aria-label="Open Menu"
                    >
                        <span data-uk-navbar-toggle-icon>&nbsp;</span>
                    </a>
                </div>

                {/* <span data-uk-navbar-toggle-icon="true" className="uk-icon uk-navbar-toggle-icon">&nbsp;<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect y="9" width="20" height="2"></rect><rect y="3" width="20" height="2"></rect><rect y="15" width="20" height="2"></rect></svg></span> */}




                <div id="mobile-width-menu" hidden>
                    <div className="uk-navbar">
                        <div className="uk-navbar-dropdown uk-flex uk-flex-column mobile-menu-container">
                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                <li>
                                    <a href="/about-us" className="bg_color border">About Us</a>
                                    {/* <ul className="submenu">
                                        <li><a href=""></a></li>
                                    </ul> */}
                                </li>
                                <li>
                                    <a href="/our-brands" className="bg_color border">Our Brands</a>
                                </li>
                                <li>
                                    <a href="/contact-us" className="bg_color border">Contact Us</a>
                                </li>
                                <li>
                                    <a href="/contact-us" className="bg_color border">Accounts Enquiries</a>
                                </li>
                                <li>
                                    <a href="/contact-us" className="bg_color border">Affiliates Enquiries</a>
                                </li>
                                <li>
                                    <a href="/contact-us" className="bg_color border">Custom Services</a>
                                </li>
                                <li>
                                    <a href="/contact-us" className="bg_color">Marketing & Press Enquiries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </nav >
        </div >

    )
}

export default Nav