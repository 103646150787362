import React from 'react';

const GoogleTagManager = ({ gtmId }) => {
  if (typeof window === 'undefined') {
    return null; // Don't render anything during server-side rendering
  }

  return (
    <script
    dangerouslySetInnerHTML={{
      __html: `
       
    var GTMh=[];
    
    var Elmo = Elmo || []; 
   


    Elmo.push({"env":"LIVE","Ernie":{"url":"","platform":"WPFE"},"Vistats":{"domain":"www.icecomparison.com","cookieDomain":".icecomparison.com","loggingEnabled":false,"adCallsLevel":"VisibleOnly","url":"https:\/\/testing.theinsurers.co.uk"}});

    function isIE() {
        var myNav = navigator.userAgent.toLowerCase();
        return (myNav.indexOf('msie') !== -1) ? parseInt(myNav.split('msie')[1]) : false;
    }

    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start':
            new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;

        var version = isIE();
        if (version !== false && version < 9)
            document.attachEvent("onreadystatechange", function() {
                if (document.readyState === "complete") {
                    document.detachEvent("onreadystatechange", arguments.callee);
                    f.parentNode.insertBefore(j, f);
                }
            });
        else
            f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'Elmo', '${gtmId}');
    `,
  }}
/>
);
};

export default GoogleTagManager;