import React from "react"
import "./ice-footer.scss"


const IceFooter = () => {


    return (
        <>
            <div>
                <div className="ice-footer-content">
                    <div className="linking-bg">
                        <div className="linking-section">
                            <ul>
                                <li>
                                    <a href="/contact-us/">Accounts Enquiries</a>
                                </li>
                                <li>
                                    <a href="/contact-us/">Affiliates Enquiries</a>
                                </li>
                                <li>
                                    <a href="/contact-us/">Customer Services</a>
                                </li>
                                <li>
                                    <a href="/contact-us/">Marketing &amp; Press Enquiries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="middle-bg">
                        <div className="middle-section">
                            <ul>
                                <li>
                                    <a target="_blank" rel="noopener" href="/privacy-and-cookie-policy/">Privacy &amp; Security</a>
                                </li>
                                <li>
                                    <a href="/sitemap/">Sitemap</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright-section">
                        <p className="copyright">© Copyright ICE 2019. All Rights Reserved. 2024.</p>
                        <p className="disclaimer">Registered in Jersey, company number: 107715. ICE Internet Comparison Experts are regulated by the Jersey Financial Services Commission
                            and are an Appointed Representative of Vast Visibility who are authorised and regulated by the Financial Authority under firm reference
                            number 566973.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IceFooter